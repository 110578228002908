import React from 'react';
import './App.css';
import type {Node} from 'react';
import { Icon } from '@iconify/react';
import { ethers } from 'ethers';
import BushicroBushi from './BushicroBushi.json';
import Staking from './Staking.json';

function cleanUpSrc(thisImg){
		if (thisImg.indexOf('ipfs://') === 0){
				thisImg = 'https://ipfs.io/ipfs/' + thisImg.substr(7);
		}
		return thisImg;
}

const Gallery = ({srcList, i, buttonsOutside, mvtIcon, popUps, setPopUps}): Node => {

	const [screenNos, setScreenNos] = React.useState(i);
	var thisImg = srcList[screenNos];

	if (!thisImg || thisImg === '-'){
		thisImg = 'https://cues.sg/client_pictures/hourglass.png'
	}else if (thisImg.indexOf('ipfs://') === 0){
	    thisImg = 'https://ipfs.io/ipfs/' + thisImg.substr(7);
			}

	function clickClose(e){
		popUps.pop();
		setPopUps([...popUps]);
	}

	function clickLeft(e){
		if (screenNos > 0){
			setScreenNos(screenNos-1);
		}else{
			setScreenNos(srcList.length - 1);
		}
	}

	function clickRight(e){
		if (screenNos < srcList.length - 1){
			setScreenNos(screenNos + 1);
		}else{
			setScreenNos(0);
		}
	}

	 return <div className='container text-center' style={{marginTop:'10%', marginBottom:'5%', width:'80%', height:'80%', borderRadius:'15px'}}>
				<div style = {{color: 'white', display:'inline', position:'relative'}}>
					<img style = {{maxHeight:'80%', maxWidth: '80%', border: '1px solid white'}} src = {thisImg} alt='' />
					<Icon onClick={clickClose} className='growButton' style={{right:0}} color='white'	height ='30' icon='eva:close-fill' />
					<div className='growButton' style={{left:(buttonsOutside ? '-50px' : 0), top:'50%'}} onClick={clickLeft}>
						<Icon height = '30' icon={mvtIcon} />
					</div>
					<div className='growButton' style={{right:(buttonsOutside ? '-50px' : 0), top:'50%'}} onClick={clickRight}>
						<Icon height = '30' icon={mvtIcon.split('left').join('right')} />
					</div>
					
				</div>
			</div>	
}

function getValueFromExistingSmartContract(instance, account, address, jsonFile, functionName, inputTypeList, outputTypeList, chainInfo, setChainInfo, updateChecks, ...argsIn){
	
	var defaultSlate = {};

	function coverValueIfNecc(type, value){
		if (type.t === 'ListType'){
			return value.map((aVal, index)=>{
				return coverValueIfNecc(type.c, aVal);
			})
		}else if (type.t === 'Object'){
			var p = {};
			type.c.forEach((aC, index)=>{
				var cc = coverValueIfNecc(aC, value[aC.n]);
				p[aC.n] = cc;
			})
			return p;
		}else if (type.t === 'UInteger' || type.t === 'Integer'){
			if (!value.hex){
	  			return ethers.BigNumber.from(value);
			}
		}else if (type.t === 'Text String'){
			return value.split('.infura').join('');
		}
		return value;
	}

	function flattenType(inputType, aI){
		if (inputType.t === 'ListType'){
			return aI.map((anInput, index)=>{
				return flattenType(inputType.c, anInput);
			}).join(', ');
		}else if (inputType.t === 'UInteger' || inputType.t === 'Integer'){
			return aI.toString();
		}else if (inputType.t === 'Boolean'){
			if (aI){
				return 'true'
			}else{
				return 'false'
			}
		}else if (inputType.t === 'Object'){
			var cc = {};
			inputType.c.forEach((anInput, index)=>{
				var p = flattenType(anInput, aI[anInput.n]);
				cc[anInput.n] = p;
			})
			return JSON.stringify(cc);
		}else if (inputType.t === 'Bytes'){
			return '_';
		}else if (inputType.t === 'Text String' || inputType.t === 'Address'){
			return aI;
		}else{
			console.warn(inputType);
			return aI;
		}
	}

	if (instance && account){

		var args = argsIn.filter((aI, index)=>{
			return index < inputTypeList.length;
		})

		var flattenedInputs = args.map((aI, index)=>{
			var inputType = inputTypeList[+index];
			return flattenType(inputType, aI);
		})

		var point = [address, functionName].concat(flattenedInputs);
		var pOut = layoutFoundationForAnObject(point, chainInfo);
		if (pOut[0] !== undefined){
			return pOut;
		}else{

			function onSuccess(value){
				var k = {checked:true}
				if (outputTypeList.length === 1){
					k[0] = coverValueIfNecc(outputTypeList[0] , value);
				}else{
					for (var i = 0; i < outputTypeList.length; i++){
						var aVal = coverValueIfNecc(outputTypeList[i], value[i]);
						k[i] = aVal;
					}
				}
				replacement(point, chainInfo, k);
				setChainInfo({...chainInfo});
			}
			function onFail(e){
				console.log(e);
			}

			function actuallyCheck(){
				var gotNotChecked = false;
				for (var i = 0; i < updateChecks.length; i++){
					if (!updateChecks[i]){
						gotNotChecked = true;
						break;
					}
				}
				if (gotNotChecked){
					setTimeout(function(e){ actuallyCheck(); }, 500);
				}else{
					cryptoAdmin(instance, {add:address, json:jsonFile}, functionName, onSuccess, onFail, argsIn[argsIn.length - 1], ...args);

				}
			}

			actuallyCheck();
			return defaultSlate;
		}
	}else{
		return defaultSlate;
	}
}

function defaultValue(type, path){
	for (var i = 0; i < path.length; i++){
		if (path[i].t === 'l'){
			type = type.c;
		}else if (path[i].t === 'oP'){
			for (var j = 0; j < type.c.length; j++){
				if (type.c[j].n === path[i].v){
					type = type.c[j].t;
					break;
				}
			}
		}
	}

	function processDefault(type){
		if (type.t === 'ListType'){
			return [];
		}else if (type.t === 'Object'){
			var out = {};
			for (var i = 0; i < type.c.length; i++){
				out[type.c[i].n] = processDefault(type.c[i].t);
			}
		}else if (type.t === 'UInteger' || type.t === 'Integer'){
			return ethers.BigNumber.from('0');
		}else if (type.t === 'Text String'){
			return '-';
		}else if (type.t === 'Address'){
			return '0x0000000000000000000000000000000000000000'
		}else if (type.t === 'Boolean'){
			return false;
		}
	}
	return processDefault(type);
}

function getInfoFromTokenURI(uri, otherInfo, setOtherInfo, pointer){

	var reference = pointer + '_' + uri;
	if (setOtherInfo){
		if (otherInfo[reference]){
			if (otherInfo[reference].loaded){
				return otherInfo[reference].v;
			}
		}else{
	        if (uri.indexOf('ipfs://') === 0){
	          uri = 'https://ipfs.io/ipfs/' + uri.substr(7);
	        }

			fetch(uri)
				.then(function(response){
					return response.json();
				})
				.then(function(json){
					var val = json[pointer].split('.infura').join('');
			        if (val.indexOf('ipfs://') === 0){
			          val = 'https://ipfs.io/ipfs/' + val.substr(7);
			        }
		            setOtherInfo((otherInfo) => ({...otherInfo, [reference]:{loaded:1, v:val}}));
				})
				.catch(error => {
					var message = error.message;
		            setTimeout(function(){ 
		              delete otherInfo[reference];
		              setOtherInfo({...otherInfo});
		            }, 1000);
					console.log(message);
				});
		        setOtherInfo((otherInfo) => ({...otherInfo, [reference]:{loading:1}}));
		}

	}
	return '';	
}

const HoverButton = ({onClick, children, className, hoverColor, disabled, style, dataTooltip, dataTooltipLocation}): Node => {
		
	const [isHover, setIsHover] = React.useState(0);

	function onMouseOver(e){
		setIsHover(true);
	}

	function onMouseOut(e){
		setIsHover(false);
	}

	function onClickHere(e){
		if (onClick){
			onClick(e);
		}
	}

	var styleX = {...style};
	if (isHover){
		styleX.backgroundColor = hoverColor;
	}
	
	return <button onClick={onClickHere} disabled={disabled} style={styleX} className={className} onMouseOut={onMouseOut} onMouseOver={onMouseOver} data-tooltip={dataTooltip} data-tooltip-location={dataTooltipLocation} >{children}</button>

}

function cryptoAdmin(instance, item, name, onSuccess, onFail, events, ...args) {
	const provider = new ethers.providers.Web3Provider(instance)
	const signer = provider.getSigner()
	const contract = new ethers.Contract(item.add, item.json.abi, signer)
	try {
		contract[name](...args).then(value=>{
			onSuccess(value);
		}).catch(err=>{
			onFail(err.message);
		});
	} catch (err) {
		onFail(err.message)
	}

	events.forEach((anEvent, index)=>{
		if (anEvent.f){
			const event1 = contract.filters[anEvent.k.name](...anEvent.k.conditions);
			contract.once(event1, ()=>{
				anEvent.f();
			})
		}else{
			const event = contract.filters[anEvent.name](...anEvent.conditions);
			contract.on(event, ()=>{
				cryptoAdmin(instance, item, name, onSuccess, onFail, [], ...args);
			})
		}
	})
}

function makeADecimalTextIntoLongText(decimalText, digits){
		var locOfDot = decimalText.indexOf('.');
		if (locOfDot === -1){
				return decimalText + makeDigits(digits);
		}else{
				var before = decimalText.substr(0, locOfDot);
				var after = decimalText.substr(locOfDot + 1);
				if (after.length > digits){
						return before + after.substr(0, digits);      
				}else{
						return before + after + makeDigits(digits - after.length);
				}
		}
}

function makeDigits(digits){
		var x = '';
		for (var i = 0; i < digits; i++){
				x += '0';
		}
		return x;
}

function listFromTwoUIntegers(a, b){
		if (a.lte(b)){
				var init = a.toNumber();
				var ender = b.toNumber();
				var out = [];
				for (var i10 = init; i10 <= ender; i10++ ){
						out.push(ethers.BigNumber.from(i10));
				}
				return out;
		}else{
				return [];
		}
}


function urlSearchParams(searchFor){
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		return urlParams.get(searchFor);
}

const TextInputRecall = ({defaultValue, style, className, onChange, onKeyDown, idNos, inputValues, setInputValues, gVs}): Node => {

		var onChangeExt = onChange;
		var onKeyDownExt = onKeyDown;
		var idOut = [idNos].concat(gVs).join('_');
		
		var value = (inputValues[idOut]? inputValues[idOut] : '');
		
		function setValue(valueIn){
				inputValues[idOut] = valueIn;
				setInputValues({...inputValues});
		}

		React.useEffect(() => {
				setValue(defaultValue);
		}, [defaultValue]);

		function onChangeL(e){
				setValue(e.target.value);
				if (onChangeExt){
						onChangeExt(e.target.value);
				}
		}

		function onKeyDownL(e){
				if (onKeyDownExt){
						onKeyDownExt(e);
				}
		}
		return <input className={className} value={value} disabled={style.disabled} onKeyDown={onKeyDownL} onChange={onChangeL} placeholder={style.placeholder} style={style} />;  
}

function shortenName(text){
				if (text.length < 9){
								return text;
				}    
				return text.substr(0, 2) + '...' + text.substr(text.length - 4);
}

function layoutFoundationForAnObject(list, chainInfo){
	var p = chainInfo;
	for (var i = 0; i < list.length; i++){
		var p1 = p[list[i]];
		if (!p1){
			p[list[i]] = {};
			p1 = p[list[i]];
		}
		p = p1;
	}
	return p;
}

function replacement(list, chainInfo, object){
	var p = chainInfo;
	for (var i = 0; i < list.length; i++){
		if (i === list.length - 1){
			p[list[i]] = object;
		}else{
			p = p[list[i]];
		}
	}
}
function Dimensions() {

	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		return {width:ethers.BigNumber.from(Math.round(width)),height:ethers.BigNumber.from(Math.round(height))};
	}

	const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

	React.useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
}


const App = (): Node => {

	const [inputValues, setInputValues] = React.useState({})
	const [otherInfo, setOtherInfo] = React.useState({});
	const [popUps, setPopUps] = React.useState([]);
	const { height, width } = Dimensions();
	const [instance, setInstance] = React.useState(false);
	const [chainInfo, setChainInfo] = React.useState({});
	const [account, setAccount] = React.useState(false);
	const [chainId, setChainId] = React.useState(ethers.BigNumber.from('0'));
	function clickActionfe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc_i_c29_8baf4a88_i_c29_493341e6(gV_fe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc){
		return function(e){
		cryptoAdmin(instance, {add:'0xB8C3257E71E8294134E9B59a498d0BAF0c72C2b0', json:BushicroBushi}, 'approve', function(){}
		, function(e0){window.alert(e0)
		}, [{k:{name:"Approval", conditions:[account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', gV_fe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc]}, f:function(){
		cryptoAdmin(instance, {add:'0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', json:Staking}, 'stake', function(){}
		, function(e1){window.alert(e1)
		}, [], gV_fe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc, {value:ethers.BigNumber.from( makeADecimalTextIntoLongText((15).toString(), 18))});}}], '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', gV_fe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc);
		e.stopPropagation();
		}
	}
	function clickActionfe_1__s_c31_86590548_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc_i_c26_b828fbca_i_c42_b9f9cec7(gV_fe_1__s_c31_86590548_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc){
		return function(e){
		cryptoAdmin(instance, {add:'0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', json:Staking}, 'unstake', function(){}
		, function(e0){window.alert(e0)
		}, [], gV_fe_1__s_c31_86590548_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc, {value:ethers.BigNumber.from( makeADecimalTextIntoLongText((15).toString(), 18))});
		e.stopPropagation();
		}
	}
	function clickActionfe_1__s_c31_86590548_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc_i_c26_b828fbca_i_c4_a13b895d(e){
		cryptoAdmin(instance, {add:'0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', json:Staking}, 'withdrawAllInterestWithoutUnstaking', function(){}
		, function(e0){window.alert(e0)
		}, []);
		e.stopPropagation();
	}
	function clickActionfe_1__s_c31_86590548_k_c47_e450cc0f_i_c0_0f2783ee_i_c49_ff34bfd9_i_c59_3f2f2661_i_c61_36bd5e20(e){
		if(!(account === false)
		) {
			cryptoAdmin(instance, {add:'0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', json:Staking}, 'addReferralAddress', function(){}
			, function(e1){window.alert(e1)
			}, [], inputValues['fe 1 _s c31_86590548 k c47_e450cc0f i c0_0f2783ee i c49_ff34bfd9 i c59_3f2f2661 i c60_9d346b5c']);
		}else{
			connectWallet(); 
		};
		e.stopPropagation();
	}
	function clickActionfe_1__s_c31_86590548_k_c47_e450cc0f_i_c0_0f2783ee_i_c49_ff34bfd9_i_c0_0ec32e00_i_c1_df88bbb1(e){
		navigator.clipboard.writeText(('https://www.cues.sg/dapps/nftform/?refer=' + account));
		e.stopPropagation();
	}
	function clickActionfe_1__s_c31_86590548_k_c47_e450cc0f_i_c0_0f2783ee_i_c49_ff34bfd9_i_c1_73fcd311_i_c3_99e94bf4(e){
		if(!(account === false)
		) {
			function downPath_0(){
				if (function(outputTypeList, pathDownList){ return getValueFromExistingSmartContract(instance, account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', Staking, 'referralRecordMap', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], account, []); }([{t:'Boolean'}, {t:'Address'}, {t:'UInteger'}, {t:'UInteger'}, {t:'UInteger'}], []).checked){
					cryptoAdmin(instance, {add:'0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', json:Staking}, 'withdrawReferral', function(){}
					, function(e1){window.alert(e1)
					}, [], function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', Staking, 'referralRecordMap', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], account, []); if (out.checked){return out[2];}else{return defaultValue(outputTypeList[2], pathDownList)}}([{t:'Boolean'}, {t:'Address'}, {t:'UInteger'}, {t:'UInteger'}, {t:'UInteger'}], []));
				}else{
					setTimeout(function(e){downPath_0()}, 500);
				}
			}
			downPath_0()
		}else{
			connectWallet(); 
		};
		e.stopPropagation();
	}
	function clickActioncn_c2_d1339868_nv_i_c1_3a5a4a1e(e){
		connectWallet(); 
		e.stopPropagation();
	}
	function getInfo(instanceIn){
		setInstance(instanceIn); setChainInfo({}); 
	}async function connectWallet(){
		if (account){
			return;
		}


		function isMobile(){
			let hasTouchScreen = false;
			if ('maxTouchPoints' in navigator) {
				hasTouchScreen = navigator.maxTouchPoints > 0;
			} else if ('msMaxTouchPoints' in navigator) {
				hasTouchScreen = navigator.msMaxTouchPoints > 0;
			} else {
				const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
				if (mQ && mQ.media === '(pointer:coarse)') {
					hasTouchScreen = !!mQ.matches;
				} else if ('orientation' in window) {
					hasTouchScreen = true; // deprecated, but good fallback
				} else {
					// Only as a last resort, fall back to user agent sniffing
					var UA = navigator.userAgent;
					hasTouchScreen =
			  		/b(BlackBerry|webOS|iPhone|IEMobile)b/i.test(UA) ||
			  		/b(Android|Windows Phone|iPad|iPod)b/i.test(UA);
				}
			}
			return hasTouchScreen    
		}

    if (isMobile() && !window.ethereum){
      if (window.confirm('Would you like to use Metamask?')) {
			  const dappUrl = window.location.href.split('//')[1];
			  const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;
			  console.log(metamaskAppDeepLink);
			  window.open(metamaskAppDeepLink, '_self');
        return;
      }
    }

		const Web3Modal = window.Web3Modal.default;
		const WalletConnectProvider = window.WalletConnectProvider.default;

		const options = new WalletConnectProvider({
		          rpc: {
		            56: 'https://bsc-dataseed.binance.org/',
		            80001: 'https://rpc-mumbai.maticvigil.com/',
		          },
		          infuraId: '8043bb2cf99347b1bfadfb233c5325c0',
		        });

		const providerOptions = {
		    walletconnect: { 
		        package: WalletConnectProvider,
		        options: options
		    }
		};

		const web3Modal = new Web3Modal({
			cacheProvider: false, // optional
			providerOptions, // required
		});
		web3Modal.clearCachedProvider();

		const d = new Date();
		let time = d.getTime();

		try {
			const instance = await web3Modal.connect(); getInfo(instance);

			// Subscribe to accounts change
			instance.on('accountsChanged', (accounts) => {
				if (accounts.length > 0){
					setAccount(accounts[0]); document.account = accounts[0]; getInfo(instance);
				}else{
					setAccount(false); document.account = false; setInstance(false); 
				}
			});

			instance.on('chainChanged', (chainId) => {
				setChainId(ethers.BigNumber.from(chainId)); getInfo(instance);
			});
			const provider = new ethers.providers.Web3Provider(instance)
			const signer = provider.getSigner()
			signer.getAddress().then(function(account){
				setAccount(account);
				document.account = account;
				provider.getNetwork().then(function(network){
					setChainId(ethers.BigNumber.from(network.chainId));
			        if (network.chainId !== 25){
			          if (window.ethereum){window.ethereum.request({method: 'wallet_addEthereumChain',params: [{chainId: `0x${Number(25).toString(16)}`,
                chainName: 'Cronos Mainnet Beta',
                nativeCurrency: {
                  name: 'CRO', symbol: 'CRO', decimals: 18
                },
                rpcUrls: ['https://evm.cronos.org'], blockExplorerUrls: ['https://cronoscan.com']
              }]});
			          }          
			        }
				})
			}).catch((err)=>{
				console.log(err);
			});

		} catch(e) {
			const d1 = new Date();
			let time1 = d1.getTime();
			if (time1 - time < 100){
				if (e.message === 'User Rejected'){
					window.alert('It seems you had been previously asked to connect to a site via metamask but the query was not completed. Please open up Metamask (by clicking on the top right icon) and click Connect in the pop-up. If that fails, please refresh this page.')
				}
			}
			return;
		}
	}
	function nav_c2_d1339868(){
		return (
		<nav className='navbar  navbar-light' style={{backgroundColor:'rgb(173, 5, 4)'}}>
			<span className='navbar-brand'><img src={'https://www.cues.sg/client_pictures/923_ZYLVeNCK.png'} style={{marginRight:'10px', width:'2.86em'}} alt='logo' /><span  style={{fontFamily:'Zilla Slab', color:'rgb(255, 255, 255)', fontWeight:(1 ? 'bold' : 'normal')}}>{'BUSHICRO'}</span></span>
			
<HoverButton style={{color:'rgb(173, 5, 4)', fontFamily:'Zilla Slab', fontWeight:(1 ? 'bold' : 'normal'), backgroundColor:'rgb(76, 138, 255)', cursor:'pointer'}} className='btn  btn-lg '  onClick={clickActioncn_c2_d1339868_nv_i_c1_3a5a4a1e}  hoverColor={'rgb(44, 117, 255)'}>{(!(account === false) ? (chainId.eq(ethers.BigNumber.from(25)) ? shortenName(account) : ('Connect to the ' + 'Cronos Mainnet Beta' + ' chain')) : 'CONNECT')}</HoverButton>
		</nav>)
	}
	var mainScreen = <div style={{color:'rgb(255, 255, 255)', fontFamily:'Lato', backgroundColor:'rgb(232, 193, 147)'}}>{nav_c2_d1339868()}
		<div style={{position:'relative', width:'100vw', overflow:'hidden', zIndex:0, backgroundColor:''}}>
			<div className=' '><div style={{backgroundColor:''}} className='      container-fluid'>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-3  mb-3  '/>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div  className='   col-3     '><img alt='generatedImage' src={cleanUpSrc('https://www.cues.sg/client_pictures/936_1jIZO7Y7.png')} style={{borderWidth:0, width:'100%'}}/></div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='  col-10      text-center' style={{backgroundColor:'rgb(255, 189, 111)', overflowWrap:false, borderColor:'rgb(0,0,0)', borderWidth:1, borderStyle:'none', borderRadius:'2.99vw', padding:10}}>
						<div key={0}><span key={0} style={{fontSize:'50px'}}><span style={{color:'rgb(173, 5, 4)'}}><b>SHINOBI</b></span></span>
						</div>
						<div key={1}><span key={0} style={{fontSize:'20px'}}><span style={{color:'#ad0504'}}>STASH | MALE</span></span>
						</div>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-3  mb-3  '/>
				</div></div>
			</div></div>
		</div>
		<div style={{position:'relative', width:'100vw', overflow:'hidden', zIndex:0, minHeight:'40em', backgroundColor:''}}>
			<div className=' '><div style={{backgroundColor:''}} className='      container-fluid'>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12'><div className='container-fluid    '><div className='row'>{function(){var srcList = ['https://www.cues.sg/client_pictures/926_QKhSgUBQ.png', 'https://www.cues.sg/client_pictures/927_wZvTC8fW.png', 'https://www.cues.sg/client_pictures/928_BUnmFSpg.png', 'https://www.cues.sg/client_pictures/929_zuCSD6Of.png']; var capList = []; return srcList.map((anImg, index)=>{

						if (!anImg || anImg === '-'){
							anImg = 'https://cues.sg/client_pictures/hourglass.png'
						}else if (anImg.indexOf('ipfs://') === 0){
						    anImg = 'https://ipfs.io/ipfs/' + anImg.substr(7);
						 }

						return <div  className=' col-3 galleryHover3  text-left ' onClick={function(e){window.scrollTo(0, 0); popUps.push({t:'gallery', srcList, i:+index, buttonsOutside:false, mvtIcon:'bxs:chevron-left-circle'}); setPopUps([...popUps]); e.stopPropagation();}}><img src={anImg} style={{width:'100%', minHeight:'95%', maxHeight:'95%'}}/><figcaption >{(capList.length > +index ? capList[+index] : null)}</figcaption></div>})}()}</div></div></div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'50px'}}><span style={{color:'rgb(173, 5, 4)'}}><b>Gallery</b></span></span>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}>
						<span key={0} style={{fontSize:'18px', color:'rgb(173, 5, 4)'}}>{(!(account === false) ? (function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0xB8C3257E71E8294134E9B59a498d0BAF0c72C2b0', BushicroBushi, 'balanceOf', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], account, []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []).gt(ethers.BigNumber.from('0')) ? 'Here are the NFTs that you can stake.' : 'You do not own any NFTs to stake') : 'Please log in to see the NFTs you have in your collection that can be staked.')}</span>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					{listFromTwoUIntegers(ethers.BigNumber.from('0'), function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0xB8C3257E71E8294134E9B59a498d0BAF0c72C2b0', BushicroBushi, 'balanceOf', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], account, []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []).sub(ethers.BigNumber.from('1'))).map((_mapVar_c24_71015889, index)=>{return function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0xB8C3257E71E8294134E9B59a498d0BAF0c72C2b0', BushicroBushi, 'tokenOfOwnerByIndex', [{t:'Address'}, {t:'UInteger'}], outputTypeList, chainInfo, setChainInfo, [], account, _mapVar_c24_71015889, []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []);}).map((gV_fe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc, gVIndex)=>{ return (
						<div key={gVIndex} className='   col-6 col-md-4 col-xl-3'><div style={{backgroundColor:''}} className='      container-fluid'>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div  className='   col-12     '><img alt='generatedImage' src={cleanUpSrc(getInfoFromTokenURI(function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0xB8C3257E71E8294134E9B59a498d0BAF0c72C2b0', BushicroBushi, 'tokenURI', [{t:'UInteger'}], outputTypeList, chainInfo, setChainInfo, [], gV_fe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc, []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'Text String'}], []), otherInfo, setOtherInfo, 'image'))} style={{borderWidth:0, width:'100%'}}/></div>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div className='  col-12  mt-2  mb-2  '/>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div className='  col-12      text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{color:'rgb(86, 86, 86)'}}>BushiToku given/day:  </span>
									<span key={1}>{(ethers.BigNumber.from('4').gt(ethers.BigNumber.from('2')) ? function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', Staking, 'dailyInterestRate', [], outputTypeList, chainInfo, setChainInfo, [], []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []).div(ethers.BigNumber.from('10').pow(ethers.BigNumber.from('4').sub(ethers.BigNumber.from('2')))).toNumber()/(10 ** ethers.BigNumber.from('2').toNumber()) : function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', Staking, 'dailyInterestRate', [], outputTypeList, chainInfo, setChainInfo, [], []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []).toNumber() / (10 ** ethers.BigNumber.from('4').toNumber())).toFixed(ethers.BigNumber.from('2').toNumber() > 100 ? 100 : ethers.BigNumber.from('2').toNumber())}</span>
								</div>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div className='  col-12  mt-2  mb-2  '/>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<HoverButton style={{color:'rgb(72, 72, 72)', fontFamily:'Zilla Slab', fontWeight:'bold', backgroundColor:'rgb(255, 222, 162)', cursor:'pointer'}} className='btn    col-12  mt-2   '  onClick={clickActionfe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc_i_c29_8baf4a88_i_c29_493341e6(gV_fe_1__s_c0_49aaebd6_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc)}  hoverColor={'rgb(255, 209, 126)'}>{'STAKE'}</HoverButton>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div className='  col-12  mt-2  mb-2  '/>
							</div></div>
						</div></div>) })}
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
				</div></div>
			</div></div>
		</div>
		<div style={{position:'relative', width:'100vw', overflow:'hidden', zIndex:0, minHeight:'40em', backgroundColor:''}}>
			<div className=' '><div style={{backgroundColor:''}} className='      container-fluid'>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-5  mb-5  '/>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'50px'}}><span style={{color:'rgb(173, 5, 4)'}}><b>Staked NFTs</b></span></span>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}>
						<span key={0} style={{fontSize:'18px', color:'rgb(173, 5, 4)'}}>{(!(account === false) ? (function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', Staking, 'numberOfTokensCurrentlyStaked', [], outputTypeList, chainInfo, setChainInfo, [], []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []).gt(ethers.BigNumber.from('0')) ? 'Here are the NFTs currently staked.' : 'No NFTs that are currently being staked.') : 'Please log in to see the NFTs that you have staked.')}</span>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					{listFromTwoUIntegers(ethers.BigNumber.from('0'), function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', Staking, 'numberOfStakedTokenIDsOfAnAddress', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], account, []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []).sub(ethers.BigNumber.from('1'))).map((_mapVar_c0_77217702, index)=>{return function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', Staking, 'whichStakedTokenIDsOfAnAddress', [{t:'Address'}, {t:'UInteger'}], outputTypeList, chainInfo, setChainInfo, [], account, _mapVar_c0_77217702, []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []);}).map((gV_fe_1__s_c31_86590548_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc, gVIndex)=>{ return (
						<div key={gVIndex} className='   col-6 col-md-4 col-xl-3'><div style={{backgroundColor:''}} className='      container-fluid'>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div  className='   col-12     '><img alt='generatedImage' src={cleanUpSrc(getInfoFromTokenURI(function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0xB8C3257E71E8294134E9B59a498d0BAF0c72C2b0', BushicroBushi, 'tokenURI', [{t:'UInteger'}], outputTypeList, chainInfo, setChainInfo, [], gV_fe_1__s_c31_86590548_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc, []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'Text String'}], []), otherInfo, setOtherInfo, 'image'))} style={{borderWidth:0, width:'100%'}}/></div>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div className='  col-12  mt-2  mb-2  '/>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div className='  col-12      text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{color:'rgb(86, 86, 86)'}}>Accum. BushiToku: </span>
									<span key={1}>{(ethers.BigNumber.from('18').gt(ethers.BigNumber.from('2')) ? function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', Staking, 'interestEarnedUpToNowBeforeTaxesAndNotYetWithdrawn', [{t:'UInteger'}], outputTypeList, chainInfo, setChainInfo, [], gV_fe_1__s_c31_86590548_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc, []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []).div(ethers.BigNumber.from('10').pow(ethers.BigNumber.from('18').sub(ethers.BigNumber.from('2')))).toNumber()/(10 ** ethers.BigNumber.from('2').toNumber()) : function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', Staking, 'interestEarnedUpToNowBeforeTaxesAndNotYetWithdrawn', [{t:'UInteger'}], outputTypeList, chainInfo, setChainInfo, [], gV_fe_1__s_c31_86590548_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc, []); if (out.checked){return out[0];}else{return defaultValue(outputTypeList[0], pathDownList)}}([{t:'UInteger'}], []).toNumber() / (10 ** ethers.BigNumber.from('18').toNumber())).toFixed(ethers.BigNumber.from('2').toNumber() > 100 ? 100 : ethers.BigNumber.from('2').toNumber())}</span>
								</div>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div className='  col-12  mt-2  mb-2  '/>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<HoverButton style={{color:'rgb(72, 72, 72)', fontFamily:'Zilla Slab', fontWeight:'bold', backgroundColor:'rgb(255, 222, 162)', cursor:'pointer'}} className='btn    col-12  mt-2   '  onClick={clickActionfe_1__s_c31_86590548_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc_i_c26_b828fbca_i_c42_b9f9cec7(gV_fe_1__s_c31_86590548_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc)}  hoverColor={'rgb(255, 209, 126)'}>{'UNSTAKE'}</HoverButton>
								<HoverButton style={{color:'rgb(72, 72, 72)', fontFamily:'Zilla Slab', fontWeight:'bold', backgroundColor:'rgb(255, 222, 162)', cursor:'pointer'}} className='btn    col-12  mt-2   '  onClick={clickActionfe_1__s_c31_86590548_k_c0_5e0472b6_i_c1_ac6bf424_i_c24_f349bedc_i_c26_b828fbca_i_c4_a13b895d}  hoverColor={'rgb(255, 209, 126)'}>{'CLAIM BUSHITOKU'}</HoverButton>
							</div></div>
							<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
								<div className='  col-12  mt-2  mb-2  '/>
							</div></div>
						</div></div>) })}
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-5  mb-5  '/>
				</div></div>
			</div></div>
			<div className=' '><div style={{backgroundColor:''}} className='      container-fluid'>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-5  mb-5  '/>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='  col-12      text-center' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'50px'}}><span style={{color:'rgb(173, 5, 4)'}}><b>Referrals </b></span></span>
						<div key={1}><span key={0} style={{fontSize:'30px'}}><span style={{color:'rgb(173, 5, 4)'}}><b>(for staking)</b></span></span>
						</div>
					</div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
					<div className='   col-11'><div style={{borderColor:'rgb(0,0,0)', borderWidth:1, borderStyle:'none', borderRadius:'0.48em', backgroundColor:'#C09F80'}} className='  mt-2  mb-2  ml-0  mr-0 '>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-6     text-left' style={{borderWidth:0, paddingLeft:25, paddingTop:5, paddingBottom:5}}><span key={0} style={{fontSize:'16px'}}><span style={{color:'rgb(86, 86, 86)'}}><b>Rewards</b></span></span>
								<div key={1}><span key={0} style={{fontSize:'16px'}}><span style={{color:'rgb(86, 86, 86)'}}>20%</span></span>
								</div>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row  no-gutters  justify-content-start  align-items-start'>
							<div className='  col-12     text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'16px'}}><span style={{color:'rgb(86, 86, 86)'}}>What are you waiting for, refer your friends and earn BushiToku!</span></span>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row  no-gutters  justify-content-start  align-items-start'>
							<div className='  col-12     text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'25px'}}><span style={{color:'rgb(173, 5, 4)'}}>Referred By</span></span>
							</div>
						</div></div>
						<div className=' '><div style={{display:(((function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', Staking, 'referralRecordMap', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], account, []); if (out.checked){return out[1];}else{return defaultValue(outputTypeList[1], pathDownList)}}([{t:'Boolean'}, {t:'Address'}, {t:'UInteger'}, {t:'UInteger'}, {t:'UInteger'}], []) === '0x0000000000000000000000000000000000000000') ? true : false) ? 'none' : ''), backgroundColor:''}} className='row   justify-content-center  align-items-start'>
							<div className='  col-10      text-left' style={{borderColor:'rgb(255, 244, 159)', borderWidth:1, borderStyle:'solid', borderRadius:'0.41em', padding:10}}>
								<span key={0} style={{color:'#565656'}}>{function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', Staking, 'referralRecordMap', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], account, []); if (out.checked){return out[1];}else{return defaultValue(outputTypeList[1], pathDownList)}}([{t:'Boolean'}, {t:'Address'}, {t:'UInteger'}, {t:'UInteger'}, {t:'UInteger'}], [])}</span>
							</div>
						</div></div>
						<div className=' '><div style={{display:(((function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', Staking, 'referralRecordMap', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], account, []); if (out.checked){return out[1];}else{return defaultValue(outputTypeList[1], pathDownList)}}([{t:'Boolean'}, {t:'Address'}, {t:'UInteger'}, {t:'UInteger'}, {t:'UInteger'}], []) === '0x0000000000000000000000000000000000000000') ? false : true) ? 'none' : ''), backgroundColor:''}} className='row   justify-content-center  align-items-start'>
							<TextInputRecall defaultValue={urlSearchParams('refer')} className='form-control   col-7       text-left ' style={{backgroundColor:'#C09F80', placeholder:'Add referral address', color:'#565656', disabled:false, borderColor:'rgb(255, 244, 159)', borderWidth:1, borderStyle:'solid', borderRadius:'0.41em'}} gVs={[]} setInputValues={setInputValues} inputValues={inputValues} idNos={'fe 1 _s c31_86590548 k c47_e450cc0f i c0_0f2783ee i c49_ff34bfd9 i c59_3f2f2661 i c60_9d346b5c'} />
							<HoverButton style={{fontFamily:'Zilla Slab', fontWeight:'bold', backgroundColor:'rgb(255, 222, 162)', cursor:'pointer'}} className='btn    col-3    '  onClick={clickActionfe_1__s_c31_86590548_k_c47_e450cc0f_i_c0_0f2783ee_i_c49_ff34bfd9_i_c59_3f2f2661_i_c61_36bd5e20}  hoverColor={'rgb(255, 209, 126)'}>{'CONFIRM'}</HoverButton>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row  no-gutters  justify-content-start  align-items-start'>
							<div className='  col-12     text-left' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'25px'}}><span style={{color:'rgb(173, 5, 4)'}}><b>Referral Link</b></span></span>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row  no-gutters  justify-content-center  align-items-start'>
							<div className='  col-9     text-left' style={{borderColor:'rgb(255, 244, 159)', borderWidth:1, borderStyle:'solid', borderRadius:'0.45em', padding:10}}>
								<span key={0} style={{fontSize:'12px', fontStyle:'italic'}}>{('https://www.cues.sg/dapps/nftform/?refer=' + account)}</span>
							</div>
							<div className='  col-1    ml-2 '><Icon height={'40px'} icon={'icon-park-outline:copy-link'} onClick={clickActionfe_1__s_c31_86590548_k_c47_e450cc0f_i_c0_0f2783ee_i_c49_ff34bfd9_i_c0_0ec32e00_i_c1_df88bbb1}  style={{color:'#76323F', cursor:'pointer'}} /> </div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1  mb-1  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row  no-gutters  justify-content-center  align-items-start'>
							<div className='  col-6      text-center' style={{borderWidth:0, padding:10}}><span key={0} style={{fontSize:'25px'}}><span style={{color:'rgb(173, 5, 4)'}}><b>Total Rewards</b></span></span>
								<div key={1}>
									<span key={0}>{function(x){try{return x.toString()}catch(err){return ''}}(function(outputTypeList, pathDownList){ var out = getValueFromExistingSmartContract(instance, account, '0x571f1F57493Ab0a4Ed98EDe9171d5874901d5b8F', Staking, 'referralRecordMap', [{t:'Address'}], outputTypeList, chainInfo, setChainInfo, [], account, []); if (out.checked){return out[2];}else{return defaultValue(outputTypeList[2], pathDownList)}}([{t:'Boolean'}, {t:'Address'}, {t:'UInteger'}, {t:'UInteger'}, {t:'UInteger'}], []))}</span><br/>
								</div>
							</div>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-1 mt-lg-3 mt-xl-4  mb-1 mb-lg-3 mb-xl-4  '/>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-center  align-items-start'>
							<HoverButton style={{fontFamily:'Zilla Slab', fontWeight:'bold', backgroundColor:'rgb(255, 222, 162)', cursor:'pointer'}} className='btn    col-6 col-md-4    '  onClick={clickActionfe_1__s_c31_86590548_k_c47_e450cc0f_i_c0_0f2783ee_i_c49_ff34bfd9_i_c1_73fcd311_i_c3_99e94bf4}  hoverColor={'rgb(255, 209, 126)'}>{'CLAIM REWARDS'}</HoverButton>
						</div></div>
						<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
							<div className='  col-12  mt-2 mt-lg-3 mt-xl-4  mb-2 mb-lg-3 mb-xl-4  '/>
						</div></div>
					</div></div>
				</div></div>
				<div className=' '><div style={{backgroundColor:''}} className='row   justify-content-start  align-items-start'>
					<div className='  col-12  mt-5  mb-5  '/>
				</div></div>
			</div></div>
		</div></div>
	var popUpOut = null; 
	var paddingBackground;
	if (popUps.length > 0){
		var whichPopUp = popUps[popUps.length - 1]; 
		var popUp = null; 
		if (whichPopUp.t === 'gallery'){
			popUp = <Gallery srcList={whichPopUp.srcList} i={whichPopUp.i} buttonsOutside={whichPopUp.buttonsOutside} mvtIcon={whichPopUp.mvtIcon} popUps={popUps} setPopUps={setPopUps} />;
		}
		popUpOut = <div style={{position:'absolute', left:0, top:0, zIndex:1500, right:0, bottom:0, backgroundColor:paddingBackground, verticalAlign:'middle'}}><table style={{width:width.toNumber(), height:height.toNumber()}}><tbody><tr><td><center>{popUp}</center></td></tr></tbody></table></div>
	}
	return <div style={{width:width.toNumber(), height:height.toNumber(), lineHeight:'normal', verticalAlign:'top', position:'relative', overflowY:'scroll'}}>{mainScreen}{popUpOut}</div>
}

export default App;